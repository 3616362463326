<template>
    <div class="demand">
        <!-- 需求 -->
        <div v-if="wishBtn" class="orderContent" @click="toAddwish">
            <i class="iconfont icon-tubiao09"></i>
            <p>心愿</p>
        </div>
        <!-- 活动 -->
        <activeData />
    </div>
</template>

<script>
import activeData from './active'
export default {
    data() {
        return {
          wishBtn: true,
        }
    },
    components:{
        activeData
    },
    created(){
      if(window.localStorage.enterpriseInfo&&window.localStorage.enterpriseInfo!='undefined'){ // 爱心企业登入
        this.wishBtn = false;
      } else if(window.localStorage.personalInformation&&window.localStorage.personalInformation!='undefined'){  // 会员登入
        this.wishBtn = true;
      } else{ // 都没登入
        this.wishBtn = false;
      }
    },
    activated(){
      if(window.localStorage.enterpriseInfo&&window.localStorage.enterpriseInfo!='undefined'){ // 爱心企业登入
        this.wishBtn = false;
      } else if(window.localStorage.personalInformation&&window.localStorage.personalInformation!='undefined'){  // 会员登入
        this.wishBtn = true;
      } else{ // 都没登入
        this.wishBtn = false;
      }
    },
    // 离开爱心企业
    beforeRouteLeave (to, from, next) {
      if(to.path!=='/detail'){
        if(window.localStorage.enterpriseInfo&&window.localStorage.enterpriseInfo!='undefined'){
          window.localStorage.userinfo = '';
        }
      }
      next();
    },
    // 进入爱心企业
    beforeRouteEnter (to, from, next) {
      // 只有会员可以发布心愿
      if(window.localStorage.enterpriseInfo&&window.localStorage.enterpriseInfo!='undefined'){ // 爱心企业登入
        window.localStorage.userinfo = window.localStorage.oldWishToken;
      }
      next();
    },
    methods:{
      toAddwish(){
        var link=window.location.href.split("#/")[0]+"#/wishList"; //心愿页面
        window.location.href = link;
      }
    }
}
</script>

<style lang="less" scoped>
.demand{
    position: relative;
    .orderContent{
        position: fixed;
        bottom: 70px;
        right: 15px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        color: #fff;
        background:rgba(19,119,226,0.8);
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        z-index: 9999;
        p{
            width: 100%;
            text-align: center;
        }
    }
}
</style>