<template>
  <div class="container titlesTop">
    <headTitle title="心愿大厅">
      <span class="status" @click="ininforMation">{{loginStatus}}</span>
    </headTitle>

    <div class="header_wrapper">
      <div class="head_list">
        <span>{{allData.sum_count}}</span>
        <span>心愿大厅</span>
      </div>
      <div class="head_list">
        <span>{{allData.claim_count}}</span>
        <span>进行中</span>
      </div>
      <div class="head_list">
        <span>{{allData.complete_count}}</span>
        <span>对接完成</span>
      </div>
    </div>
    <div class="huadongneirong">
      <slidepage :navData="navDatafa" :navStyle="{'text-align':'center'}">
        <template v-for="(item,index) in navDatafa" v-slot:[slide+index]>
          <activeList
            :key="index"
            :id="item.id"
            :title="item.name"
            :dateParams="dateParams"
            :ref="'changeSonData' + index"
          />
        </template>
      </slidepage>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import slidepage from "../cllComponents/slidePage.vue";
import activeList from "./activeDownUp";
// import Tree from "../massorder/tree";
import Bus from "../../bus";
import wishApi from "@/api/wish";
import { Toast } from "vant";
export default {
  data() {
    return {
      slide: "slide",
      navDatafa: [], //导航数据
      nowBlock: 0,
      navStyle: { background: "#fff" }, //导航背景
      params: {
        appName: this.appName,
        typeName: "",
      },
      dateParams: {
        regionId: "", // 区域id
        year: "",
        month: "",
      },
      wishAllData: [],
      allData: [], // 心愿大厅统计数据
      tree_show: false, // 区域树结构
      list: [], // 区域列表
      fullDate: "",
      regionName: "",
      showPicker: false, //日期弹出框
      dateColumns: [
        {
          values: ["2018", "2019", "2020", "2021"],
          defaultIndex: 2,
        },
        {
          values: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
          defaultIndex: 1,
        },
      ],
      loginStatus: '未登入',
      headAn: false
    };
  },
  components: {
    slidepage,
    activeList,
    // Tree,
  },
  created() {
    this.headAn = this.judgetPhone();

    Bus.$on("listenemit", (data) => {
      this.dateParams.regionId = data.keyId;
      this.regionName = data.name;
      this.getWishNumByRegion();
      this.changeTableData();
      this.tree_show = false;
    });

    if (window.localStorage.enterpriseInfo && window.localStorage.enterpriseInfo != 'undefined') { // 爱心企业登入
      this.loginStatus = '企业';
    } else
      if (window.localStorage.personalInformation && window.localStorage.personalInformation != 'undefined') {  // 会员登入
        this.loginStatus = '会员';
      } else { // 都没登入
        this.loginStatus = '未登入';
      }
  },
  activated() {
    if (window.localStorage.enterpriseInfo && window.localStorage.enterpriseInfo != 'undefined') { // 爱心企业登入
      this.loginStatus = '企业';
    } else
      if (window.localStorage.personalInformation && window.localStorage.personalInformation != 'undefined') {  // 会员登入
        this.loginStatus = '会员';
      } else { // 都没登入
        this.loginStatus = '未登入';
      }
  },
  mounted() {
    this.getAllData(); // 数据统计
    this.getWishClass();
  },
  methods: {
    // 进入信息详情页
    ininforMation() { // 进入信息详情页
      this.$router.push('/myInfo');
    },
    // 返回上一层页面
    returnPageTop() { // 如果是企业登入
      this.$router.go(-1);
    },
    // 改变表格数据
    changeTableData() {
      for (var i = 0; i < this.navDatafa.length; i++) {
        var name = "changeSonData" + i;
        if (this.$refs[name]) {
          this.$refs[name][0].refresh();
        }
      }
    },
    getAllData() {
      let params = { // app名字
        appName: this.appName
      }
      wishApi.wishStatistics(params).then(res => {
        console.log(res);
        if (res.success) {
          this.allData = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    // 获取心愿服务类型
    getWishClass() {
      let params = { // app名字
        appName: this.appName
      }
      wishApi.wishStyle(params).then(res => {
        console.log(res);
        if (res.success) {
          this.navDatafa = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    }
    // 获取区域部分
    // getRegionData() {
    //   this.$http
    //     .get(
    //       this.javalinkTwo +
    //         "app/ap_member//member/query/region/tree?appName=" +
    //         this.params.appName
    //     )
    //     .then((resp) => {
    //       //   console.log(resp.data);
    //       this.list = resp.data.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>

<style lang="less" scoped>
.status {
  font-size: 16px;
  // height: 24px;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .media {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .huadongneirong {
    width: 100%;
    height: calc(100% - 28vw);
  }
  .header_wrapper {
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    background-image: url("../../assets/images/applictionbg.png");
    background-position: center;
    background-size: cover;
    width: calc(100% - 20px);
    margin: 0.1rem auto;
    height: 22vw;
    align-items: center;
    .head_list {
      padding: 4vw;
      display: flex;
      flex-flow: column;
      align-items: center;
      span {
        color: #fff;
        &:nth-child(1) {
          font-size: 0.38rem;
          margin-bottom: 2vw;
        }
        &:nth-child(2) {
          font-size: 0.28rem;
          opacity: 0.7;
        }
      }
    }
  }
  .tree_box {
    padding: 5vw 0 10vw;
  }
}
</style>