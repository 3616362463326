<template>
  <div class="activeDownUp" id="activeDownUp">
    <mescroll-vue class="mescroll gundong" ref="mescroll" :up="mescrollUp" @init="mescrollInit">
      <!-- <div :id="'activeDemand'+id"></div> -->
      <div v-if="dataLink.length !== 0">
        <div
          class="activeContent"
          v-for="(item,index) in dataLink"
          :key="index"
          @click="activeDetail(item)"
        >
          <div class="demandList">
            <div class="left_title">
              <img src="../../assets/images/headStart.png" alt />
              <span>{{title}}</span>
            </div>
            <!-- 状态 -->
            <van-button v-if="item.status === 3" type="primary" round size="small">已对接</van-button>
            <van-button v-else type="danger" round size="small">未对接</van-button>
            <!-- <p v-if="item.orderCount" style="color:#67c23a">已认领</p>
            <p v-else style="color:#e6a23c">未认领</p>-->
          </div>
          <!-- 显示内容 -->
          <div class="demandContent">
            <p class="fontAlin">
              <span>许愿群众</span>
              <span>{{item.memberName}}</span>
            </p>
            <p class="fontAlin">
              <span>心愿描述</span>
              <span>{{item.description}}</span>
            </p>
            <div class="bottom_det">
              <p>查看详情</p>
              <i class="iconfont icon-fanhui-copy"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty_sign">暂无数据~</div>
    </mescroll-vue>
    <!-- <div v-else>暂无数据</div> -->
  </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue"; //上拉加载，下拉刷新
import wishApi from "@/api/wish";
import { Toast } from "vant";
export default {
  props: {
    id: {
      //导航数据
      type: Number,
      default: () => 0, //必须是一个函数
      required: true,
    },
    title: {
      //导航数据
      type: String,
      default: () => "", //必须是一个函数
      required: true,
    },
    dateParams: {
      //时间和区域数据
      type: Object,
      default: () => "", //必须是一个函数
      required: false,
    },
  },
  components: {
    MescrollVue,
  },
  data() {
    return {
      dataLink: [], //数据
      mescrollUp: {
        callback: this.activeData, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10, // 每页数据的数量
        },
        htmlNodata: '<p class="upwarp-nodata">-- 到底啦 --</p>',
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        auto: true,
        mustToTop: true,
        // empty: {
        //     // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
        //     warpId: '', // 父布局的id;
        //     icon: require('../../assets/mescroll/mescroll-empty.png'), // 图标,支持网络图
        //     tip: '暂无相关数据~', // 提示
        //     // btntext: '去逛逛 >', // 按钮,默认""
        //     // btnClick () { // 点击按钮的回调,默认null
        //     //     alert('点击了按钮,具体逻辑自行实现')
        //     // }
        // },
        lazyLoad: {
          use: true, // 是否开启懒加载,默认false
        },
      },
      appName: this.appName,
      regionId: "",
      year: "",
      month: "",
      typeName: "",
      params: {},
      mescroll: null,
      allpage: {
        num: 1,
        size: 10,
        time: null,
      },
    };
  },
  created() {
    this.typeName = this.title;
    // this.params = this.dateParams;
  },
  methods: {
    // 上拉加载，下拉刷新初始化
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    // 手动刷新数据
    refresh() {
      this.activeData(this.allpage, this.mescroll);
    },
    // 根据分类名称查询心愿列表
    activeData(page, mescroll) {
      var params = {
        appName: this.appName,
        typeName: this.typeName,
        page: page.num,
        size: page.size
        // 查询条件
        // regionid:this.params.regionId,
        // year:this.params.year,
        // month:this.params.month
      }
      wishApi.wishPage(params).then(res => {
        if (res.success) {
          if (page.num === 1) {
            this.dataLink = [];
          }
          this.dataLink = this.dataLink.concat(res.data.items);
          mescroll.endSuccess(res.data.items.length);
        } else {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr();
        }
      }).catch((err) => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        mescroll.endErr();
      });
      // this.$http
      //   .get(
      //     this.javalinkTwo +
      //       `app/ap_member//member/wish/query_page?regionid=${this.params.regionId}&year=${this.params.year}&month=${this.params.month}`
      //   )
      //   .then((res) => {
      //     console.log(res.data);
      //     if (res.data.success) {
      //       if (page.num === 1) {
      //         this.dataLink = [];
      //       }
      //       this.dataLink = this.dataLink.concat(res.data.data.items);
      //       mescroll.endSuccess(res.data.data.items.length);
      //     } else {
      //       // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
      //       mescroll.endErr();
      //     }
      //   })
      //   .catch((err) => {
      //     // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
      //     mescroll.endErr();
      //   });
    },
    activeDetail(item) {
      if (this.isToken()) {
        var link; // 跳转链接
        link = window.location.href.split("#/")[0] + "#/detail?id=" + item.id; //链接
        window.location.href = link;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.activeDownUp {
  width: 100%;
  height: 100%;
  background: #f5f6f7;
}
.activeContent {
  width: 92vw;
  height: auto;
  border-radius: 4px;
  margin: 10px auto;
  border: 1px solid #e8e8e8;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .demandList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    // border-bottom: 1px solid #E8E8E8;
    .left_title {
      display: flex;
      align-items: center;
      width: 66vw;
      img {
        width: 7vw;
        margin-right: 3vw;
      }
      span {
        font-size: 0.31rem;
      }
    }
  }
  .demandContent {
    padding-top: 10px;
    p {
      line-height: 0.4rem;
      font-size: 0.33rem;
      display: flex;
      span {
        &:nth-child(1) {
          color: #cecece;
          margin-right: 3vw;
        }
        &:nth-child(2) {
          display: inline-block;
          width: 50vw;
        }
      }
    }
    .bottom_det {
      margin-top: 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        color: #cecece;
      }
    }
  }
}
.empty_sign {
  text-align: center;
  font-size: 0.4rem;
  margin-top: 5vw;
}
</style>